<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-08-02 09:27:34
 * @Description: 政策类型列表
-->

<template>
  <div class="policyTypeManagement">
    <ds-header title="政策类型管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <!-- <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择所属项目"
          v-model="searchForm.projectId"
        >
          <a-select-option value="">全部项目</a-select-option>
          <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <!-- <el-cascader v-model="searchForm.projectId" style="width: 200px"
              :options="projectName"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
        <a-cascader expandTrigger="hover" style="width: 100%;" v-model="searchForm.projectId"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectName"
          :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
          placeholder="请选择所属项目" />
      </a-form-item>
      <a-form-item label="政策类型">
        <a-input v-model.trim="searchForm.policyTypeName" placeholder="请输入政策类型"></a-input>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择状态"
          v-model="searchForm.policyTypeStatus"
        >
          <a-select-option v-for="item in policyTypeStatus" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div style="margin: 10px 0">
      <a-button type="primary" @click="addPolicyType()"> <a-icon type="plus" /> 新增政策类型 </a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
      <div slot="projectName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.projectName }}</span>
          </template>
          <div
            :style="{
              width: '260px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.projectName }}
          </div>
        </a-tooltip>
      </div>

      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="isEnabled(record)" class="btn"
          >{{ record.policyTypeStatus === 0 ? "禁用" : "启用" }}
        </a-button>
        <a-button type="link" @click="edit(record)" class="btn"> 编辑 </a-button>
        <a-button type="link" @click="deletePolicy(record)" style="color: #f5222d" class="btn"> 删除 </a-button>
      </span>
    </a-table>
    <a-modal v-model="isShowDialog" :title="dialogTitle" width="600px" @ok="submit" v-if="isShowDialog">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" :rules="rules">
        <a-form-model-item label="所属项目" prop="projectIdList">
          <!-- <a-select
            mode="multiple"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            placeholder="请选择所属项目"
            v-model="form.projectIdList"
          >
            <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
              {{ item.projectName }}
            </a-select-option>
          </a-select> -->
          <el-cascader v-model="form.projectIdList" style="width: 100%"
              :options="projectName2"
              :collapse-tags = "true"
              :props="{emitPath:false,multiple: true,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader>
        </a-form-model-item>
        <a-form-model-item label="政策类型名称" prop="policyTypeName">
          <a-input
            placeholder="请输入政策类型名称，不超过10个字符"
            :max-length="10"
            v-model.trim="form.policyTypeName"
          />
        </a-form-model-item>
        <a-form-model-item label="状态" :span="24" prop="policyTypeStatus">
          <a-radio-group v-model="form.policyTypeStatus">
            <a-radio :value="0"> 启用 </a-radio>
            <a-radio :value="1"> 禁用 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import * as api from "@/api/entrepreneurClubManagement/policyManagement";
export default {
  name: "policyTypeManagement",
  data() {
    return {
      // 查询条件
      searchForm: {
        // 所属项目
        projectId: [],
        // 政策类型
        policyTypeName: "",
        // 状态
        policyTypeStatus: "",
      },

      // 状态
      policyTypeStatus: [
        {
          value: "",
          label: "全部状态",
        },
        {
          value: 0,
          label: "已启用",
        },
        {
          value: 1,
          label: "已禁用",
        },
      ],
      // 所属项目
      projectName: [],
      // 表格
      table: {
        columns: [
          {
            title: "政策类型",
            dataIndex: "policyTypeName",
            key: "policyTypeName",
            width: 180,
          },
          {
            title: "所属项目",
            key: "projectName",
            width: 280,
            scopedSlots: { customRender: "projectName" },
          },
          {
            title: "状态",
            dataIndex: "policyTypeStatus",
            key: "policyTypeStatus",
            width: 120,
            customRender(text) {
              return text == 1 ? "已禁用" : "已启用";
            },
          },
          {
            title: "创建人",
            dataIndex: "createIdShow",
            key: "createIdShow",
            width: 150,
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "最后修改人",
            dataIndex: "modifiedIdShow",
            key: "modifiedIdShow",
            width: 150,
            customRender(text) {
              return text === null ? "-" : text;
            },
          },
          {
            title: "最后修改时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            width: 180,

            customRender(text) {
              return text === null ? "-" : text;
            },
          },
          {
            title: "操作",
            key: "action",
            width: "150px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            fixed:'right'
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 弹框标题
      dialogTitle: "",
      // 是否展示数据统计
      isShowDialog: false,
      form: {
        // 所属项目
        projectIdList: [],
        // 政策类型名称
        policyTypeName: "",
        // 政策类型状态
        policyTypeStatus: 0,
      },
      rules: {
        policyTypeName: [{ required: true, message: "请输入政策类型名称", trigger: "blur" }],
        policyTypeStatus: [{ required: true, message: "请选择政策类型状态", trigger: "change" }],
        projectIdList: [{ required: true, message: "请选择所属项目", trigger: "change" }],
      },
      projectName2:[]
    };
  },
  components: {},
  mounted() {
    // 获取列表数据
    this.getList();
    // 获取所属项目
    this.getProject();
    this.getProject2();
    let handleResize = ()=> {
      let width = document.getElementsByClassName('policyTypeManagement')[0].clientWidth;
      console.log(width,'=====');
      if(width>1520){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
  methods: {
    // 查询
    search() {
      this.table.pagination.pageSize = 10;
      this.table.pagination.current = 1;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 所属项目
        projectId: [],
        // 政策类型
        policyTypeName: "",
        // 状态
        policyTypeStatus: "",
      };
      this.table.pagination.pageSize = 10;
      this.table.pagination.current = 1;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
          projectId:this.searchForm.projectId.length?this.searchForm.projectId[this.searchForm.projectId.length-1]:''
        };
        const res = await api.policyTypeList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 新增通报政策
    addPolicyType() {
      this.form = {
        // 所属项目
        projectIdList: [],
        // 政策类型名称
        policyTypeName: "",
        // 政策类型状态
        policyTypeStatus: 0,
      };
      this.dialogTitle = "新增政策类型";
      this.isShowDialog = true;
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 删除
    deletePolicy(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await api.policyTypeDelete(record.policyTypeId);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功!");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {
            _this.$message.error("删除失败");
          }
        },
        onCancel() {},
      });
    },
    // 禁用/启用
    isEnabled(record) {
      const _this = this;
      let title;
      let content;
      let policyTypeStatus = 0;
      if (record.policyTypeStatus === 1) {
        title = "确认启用该政策类型";
        content = "";
        policyTypeStatus = 0;
      } else {
        title = "确认禁用该政策类型";
        content = "";
        policyTypeStatus = 1;
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          const res = await api.policyTypeEnable(record.policyTypeId, policyTypeStatus);
          if (res.code === "200") {
            _this.$message.success("操作成功");
            _this.getList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },
    // 编辑
    edit(record) {
      this.dialogTitle = "编辑政策类型";
      this.isShowDialog = true;
      this.getDetail(record.policyTypeId);
    },
    // 获取详情
    async getDetail(policyTypeId) {
      try {
        const res = await api.policyTypeDetail(policyTypeId);
        if (res.code === "200") {
          this.form.projectIdList = [];
          this.form.policyTypeName = res.data.policyTypeName;
          this.form.policyTypeStatus = res.data.policyTypeStatus;
          this.form.policyTypeId = res.data.policyTypeId;
          res.data.projectList.forEach((item) => {
              this.form.projectIdList.push(item.projectId);
          })
          
        }
      } catch (error) {
        console.log(error,'==========>');
      }
    },
    // 确定
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.form.policyTypeId) {
              const res = await api.policyTypeEdit(this.form);
              if (res.code === "200") {
                this.$message.success("编辑成功");
                this.isShowDialog = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            } else {
              const res = await api.policyTypeAdd(this.form);
              if (res.code === "200") {
                this.$message.success("新增成功");
                this.isShowDialog = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            }
          } catch (error) {}
        } else {
        }
      });
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject({ check_type: false });
        if (res.code === "200") {
          this.projectName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    async getProject2() {
      try {
        const res = await api.getShunyiAllProject({ check_type: true });
        if (res.code === "200") {
          this.projectName2 = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
::v-deep .ant-table-thead>tr>th {
  padding: 8px 16px;
}

::v-deep .ant-table-tbody>tr>td {
  padding: 8px 16px;
}
</style>